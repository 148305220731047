<ion-header>
	<ion-toolbar color="budget">
		<ion-buttons slot="start">
			<ion-back-button></ion-back-button>
			<ion-menu-toggle>
				<ion-button>
					<ion-icon name="menu"></ion-icon>
				</ion-button>
			</ion-menu-toggle>
		</ion-buttons>
		<ion-title>Accueil</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content>
	<div class="accueil-header">
    <img src="assets/img/logo.svg" alt="Logo Smart'Budget udaf03">
  </div>
  <div class="ion-padding ion-text-center accueil-info">

		<smart-search-bar (goTo)="goToPage($event)"></smart-search-bar>

  	<p *ngIf="apitoken != null">
	  	<ion-button (click)="goToAccount()" *ngIf="solde_fin_de_mois > 0" color="secondary" expand="full">Mon futur solde : {{solde_fin_de_mois}} €</ion-button>
	    <ion-button (click)="goToAccount()" *ngIf="solde_fin_de_mois <= 0" color="danger" expand="full">Mon futur solde : {{solde_fin_de_mois}} €</ion-button>
	</p>
	 <p *ngIf="apitoken == null">
	    <ion-button (click)="goToLogin()" color="ui" expand="full">Se connecter</ion-button>
	</p>
	<ion-grid>
	  <ion-row>
	    <ion-col class="text-on-bottom" *ngFor="let page of pages">
	      <ion-button class="ion-color" (click)="goToPage({anchor: '', slug: page.slug, color: page.color_primary})" [style.background-color]="page.color_primary" expand="small">
					<ion-icon slot="icon-only" [src]="page.picto"></ion-icon>
				</ion-button>
	      <label>{{page.title.rendered}}</label>
	    </ion-col>
		</ion-row>
	</ion-grid>
	<p>
	    <ion-button (click)="goToQuestions()" color="primary" expand="full">Questions & Coordonnées</ion-button>
	</p>

  </div>
</ion-content>
