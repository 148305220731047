import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { UserData } from '../../providers/user-data';

import { LosePassword } from '../../interfaces/losepassword-options';
import { AlertController, LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'page-losepassword',
  templateUrl: 'losepassword.html',
  styleUrls: ['losepassword.scss']
})
export class LosepasswordPage {
  login: LosePassword = {username: ''};
  submitted = false;
  data: any;

  constructor(public userData: UserData, public http: HttpClient, public alertCtrl: AlertController, public loadingCtrl: LoadingController) {
  }

  async onLogin(form: NgForm) {
    this.submitted = true;

    if (form.valid) {
      var regex = /^[a-zA-Z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/;
      if (!regex.test(this.login.username)) {
        const alertSuccess = await this.alertCtrl.create({
          header: 'Problème !',
          subHeader: 'E-mail incorrect',
          buttons: ['OK']
        });
        return alertSuccess.present();
      }

      let loading = await this.loadingCtrl.create({
        message: "Vérification en cours ..."
      });
      loading.present();
      //let datain = JSON.stringify({"email": this.login.username});
      this.http.get('https://www.smartbudget-udaf03.fr/api/send_mail_password?email=' + this.login.username).subscribe(
        async (data) => {
          this.data = data;
          const alertSuccess = await this.alertCtrl.create({
            header: 'Mot de passe perdu',
            subHeader: 'Un mail vous a été envoyé',
            buttons: [{text: 'OK'}]
          });
          loading.dismiss();
          alertSuccess.present();
        },

        async (error) => {
          const alertSuccess = await this.alertCtrl.create({
            header: 'Problème !',
            subHeader: 'Aucune connexion à internet',
            buttons: [{text: 'OK'}]
          });
          console.log(error);
          loading.dismiss();
          alertSuccess.present();
        }
      );
    }
  }
}
