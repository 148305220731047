import { Injectable } from '@angular/core';

@Injectable()

export class AccordeonModule {
	

	constructor() {
	    
	}
	
	Accordeon(id) {
	     
	    var enf = document.getElementById("e-"+id);
	    var par = document.getElementById("p-"+id);
		var icon = document.getElementById("i-"+id);
		
	    par.classList.toggle('active');

	    if (enf.style.display === "block") {
	        enf.style.display = "none";
			icon.classList.remove("udaf-remove");
	        icon.classList.add("udaf-add");

	    } else {
	        enf.style.display = "block";
	        icon.classList.remove("udaf-add");
	        icon.classList.add("udaf-remove");
	    }
	    
	}
}
