import { Component } from '@angular/core';

import { UserData } from '../../providers/user-data';
import { Storage } from '@ionic/storage';
import 'chartjs-plugin-datalabels';
import { AccordeonModule } from '../../providers/accordeon-module/accordeon-module';
import { AlertController, LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { AccountPage } from '../account/account';
import { LoginPage } from '../login/login';
import { ExternalPage } from '../external/external';

@Component({
  selector: 'page-histo',
  templateUrl: 'histo.html'
})
export class HistoPage {

  username: string;
  apitoken: string;
  histosoldes: any;


  public doughnutAll = Array();
  public doughnutChartLabels = Array();
  public doughnutChartData = Array();
  public doughnutChartColors = Array();

  constructor(public loadingCtrl: LoadingController, public storage: Storage, public http: HttpClient, public alertCtrl: AlertController, public userData: UserData, public accordeonModule: AccordeonModule) {
  }

  ngOnInit() {
    this.storage.get('apitoken').then(async (apitoken) => {
      let loading = await this.loadingCtrl.create({
        message: "Chargement en cours ..."
      });
      loading.present();
      this.apitoken = apitoken;
      if (this.apitoken) {
        this.http.get('https://www.smartbudget-udaf03.fr/api/historique_solde?api_token=' + this.apitoken).subscribe(
          data => {
            this.histosoldes = data;

            for (var i = 0; i < this.histosoldes.length; i++) {

              this.doughnutChartLabels[i] = this.histosoldes[i][1];
              this.doughnutChartData[i] = this.histosoldes[i][0];
              this.doughnutChartColors[i] = [{backgroundColor: this.histosoldes[i][2]}];
              this.doughnutAll[i] = {
                "periode": this.histosoldes[i][4],
                "cumul": this.histosoldes[i][3],
                "doughnutChartLabels": this.doughnutChartLabels[i],
                "doughnutChartData": this.doughnutChartData[i],
                "doughnutChartColors": this.doughnutChartColors[i],
                "ind": i
              };


            }

            loading.dismiss();
          },
          error => {
            loading.dismiss();
            console.log(error);
            alert('Aucune connexion à internet');
            return;
          }
        );
      } else {
        loading.dismiss();
        this.userData.getNav().setRoot(LoginPage);
      }
    });
  }

  Accordeon(id) {
    this.accordeonModule.Accordeon(id);
  }

  goToAccount() {
    this.userData.getNav().setRoot(AccountPage);
  }

  ngAfterViewInit() {
    this.getUsername();
  }

  updatePicture() {
    console.log('Clicked to update picture');
  }

  // Present an alert with the current username populated
  // clicking OK will update the username and display it
  // clicking Cancel will close the alert and do nothing
  async changeUsername() {
    let alert = await this.alertCtrl.create({
        header: 'Change Username',
        buttons: [
          {text: 'Cancel'},
          {
            text: 'Ok',
            handler: (data: any) => {
              this.userData.setUsername(data.username);
              this.getUsername();
            }
          }
        ],
        inputs: [
          {
            name: 'username',
            value: this.username,
            placeholder: 'username'
          }
        ]
      })
    ;
    alert.present();
  }

  getUsername() {
    this.userData.getUsername().then((username) => {
      this.username = username;
    });
  }

  changePassword() {
    console.log('Clicked to change password');
  }

  logout() {
    this.userData.logout();
    this.userData.getNav().setRoot(LoginPage);
  }

  // Doughnut
  public doughnutChartType: string = 'pie';
  public doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 10,
        fontSize: 10
      }
    },
    plugins: {
      datalabels: {
        font: {weight: 'bold', size: 10},
        color: 'white',
        rotation: 45,
        align: 'end',
        //formatter: function(value, context) {
        formatter: function (value) {
          return value + ' € ';
        }
      }
    }
  };

// events
  public chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // get value by index
        //const value = chart.data.datasets[0].data[clickedElementIndex];
        if (label.substr(0, 3) == 'Tra') {
          this.userData.updatePage({slug: 'transport', color: this.userData.page.color, anchor: null});
          this.userData.getNav().setRoot(ExternalPage);
        }
        if (label.substr(0, 3) == 'San') {
          this.userData.updatePage({slug: 'sante', color: this.userData.page.color, anchor: null});
          this.userData.getNav().setRoot(ExternalPage);
        }
        if (label.substr(0, 3) == 'Log') {
          this.userData.updatePage({slug: 'logement', color: this.userData.page.color, anchor: null});
          this.userData.getNav().setRoot(ExternalPage);
        }
        if (label.substr(0, 3) == 'Com') {
          this.userData.updatePage({slug: 'communication', color: this.userData.page.color, anchor: null});
          this.userData.getNav().setRoot(ExternalPage);
        }
        if (label.substr(0, 3) == 'Loi') {
          this.userData.updatePage({slug: 'loisirs', color: this.userData.page.color, anchor: null});
          this.userData.getNav().setRoot(ExternalPage);
        }

      }
    }
  };

  public chartHovered(e: any): void {
    console.log(e);
  }

}
