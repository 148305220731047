<ion-header>
  <ion-toolbar color="ui">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
      <ion-menu-toggle>
        <ion-button>
          <ion-icon name="menu"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
    <ion-title>Mentions légales</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-card>
    <ion-card-content>
      <ion-card-title class="title" color="ui">Smart'Budget</ion-card-title>
      <p>Smart'Budget est une application proposée par l’UDAF 03 (Union Départementale des Associations Familiales de l’Allier) afin de sensibiliser les jeunes à la maîtrise de leur budget et de leur donner conseils et astuces pour gérer au mieux leur argent au quotidien.</p>
      <ul>
        <li>
          <strong>Siège de l'association</strong> : UDAF de l’Allier - 19 rue de Villars - CS 50 546 - 03005 MOULINS Cedex<br/> SIRET 779 040 898 00024 – Code APE 9499Z<br/> Association Reconnue d’utilité publique – Ordonnance du 3 mars 1945 et loi du 11 juillet 1975<br/> Déclarée le 12 octobre 1945 (JO du 24 octobre 1945)
        </li>
        <li><strong>Responsable de publication</strong> : Béatrice VIGNAUD, Présidente de l’UDAF de l’Allier</li>
        <li><strong>Téléphone</strong> : 04 70 48 70 62</li>
        <li><strong>Mail</strong> : contact@udaf03.fr</li>
      </ul>
      <ion-card-subtitle class="subtitle">Remerciements</ion-card-subtitle>
      <p>L’UDAF 03 remercie les 27 élèves des établissements qui se sont associés à ce projet à l’occasion de la Journée « Jeunes, Acteurs et Responsables 2018 » :</p>
      <ul>
        <li>Terminale Bac Pro Gestion Administration du Lycée Professionnel Sainte Louise de Montluçon</li>
        <li>2nde Bac Pro ASSP du Lycée Anna Rodier à Moulins</li>
        <li>Bac Pro Commerce et Bac Pro Accueil d’IFI 03 à Avermes</li>
        <li>1ère année de BTS Economie Sociale et Familiale de la Maison Familiale Rurale d’Escurolles</li>
        <li>CAPA Services aux Personnes et Vente en Espace Rural de la Maison Familiale Rurale de Saint Léopardin d’Augy</li>
        <li>Bac Pro ASSP du Lycée Geneviève Vincent de Commentry.</li>
      </ul>
      <p>L’UDAF 03 remercie aussi La Banque de France d’avoir participé à ce projet.</p>
      <p>Merci aux membres du groupe de travail ISBF (Information et Soutien au Budget Familial) qui ont piloté la création de cette application : Julie AUGUSTE-LIONNET, Caroline BELLOT, Sandrine D’ARCANGELO, Christine DEVAUX, Joël FAVIER, Emilie LAMBERT, Pauline MARCHAND et Estelle PENAY.</p>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <ion-card-title class="title" color="ui">Politique de confidentialité</ion-card-title>
      <ion-card-subtitle class="subtitle">Collecte de l’information</ion-card-subtitle>
      <p>Nous recueillons des informations lorsque vous créez un compte. Les informations recueillies incluent votre nom et votre adresse e-mail.</p>

      <ion-card-subtitle class="subtitle">Utilisation des informations</ion-card-subtitle>
      <p>Toutes les informations que nous recueillons, de façon sécurisée, sont utilisées pour la gestion de votre compte, de votre budget et du bon fonctionnement de l'application Smart'Budget.</p>

      <ion-card-subtitle class="subtitle">Divulgation à des tiers</ion-card-subtitle>
      <p>Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous aident à exploiter l'application, tant que ces parties conviennent de garder ces informations confidentielles.</p>

      <ion-card-subtitle class="subtitle">Protection des informations</ion-card-subtitle>
      <p>Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage à la pointe de la technologie pour protéger les informations sensibles transmises en ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.</p>

      <ion-card-subtitle class="subtitle">Supprimer son compte</ion-card-subtitle>

      <p>Nous utilisons l’adresse e-mail que vous fournissez pour gérer votre compte. Vous disposez de droits Informatique et Libertés sur les données vous concernant, que vous pouvez exercer en adressant une demande par mail à contact@udaf03.fr.</p>

      <ion-card-subtitle class="subtitle">Consentement</ion-card-subtitle>
      <p>En utilisant notre site, vous consentez à notre politique de confidentialité.</p>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <ion-card-title class="title" color="ui">Développement & Hébergement</ion-card-title>
      <p>Développée par <a onclick="window.open('https://www.bongo.fr', '_system', 'location=yes')">Bongo</a>.</p>
      <p>Base de données hébergée sur une plateforme OVH <a
        onclick="window.open('https://www.ovh.com/', '_system', 'location=yes')">https://www.ovh.com/</a>.</p>
    </ion-card-content>
  </ion-card>

</ion-content>
