import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserData } from '../../providers/user-data';
import { RevenuOptions } from '../../interfaces/revenu-options';
import { Storage } from '@ionic/storage';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'page-revenus',
  templateUrl: 'revenus.html',
  styleUrls: ['revenus.scss'],
})
export class RevenusPage {
  revenu: RevenuOptions = {frequence: '', libelle: '', poste: '', montant: 0};
  apitoken: any;
  revenus: any;
  submitted = false;
  data: any;
  loading: any;

  constructor(public modalController: ModalController, public storage: Storage, public userData: UserData, public http: HttpClient, public alertCtrl: AlertController, public loadingCtrl: LoadingController) {

  }

  ngOnInit() {
    this.revenu.frequence = 'NON';
    this.storage.get('apitoken').then(async (apitoken) => {
      this.loading = await this.loadingCtrl.create({
        message: "Chargement en cours ..."
      });
      this.loading.present();
      this.apitoken = apitoken;
      if (this.apitoken) {
        this.getRevenue();
      } else {
        this.loading.dismiss();
      }
    });
  }

  getRevenue() {
    this.http.get('https://www.smartbudget-udaf03.fr/api/postes_revenu?api_token=' + this.apitoken).subscribe(
      data => {
        this.loading.dismiss();
        this.revenus = data;
        console.log(data)
        this.revenu.poste = this.revenus[0].id;
      },
      error => {
        this.loading.dismiss();
        console.log(error);
        return;
      });
  }

  async onRevenu(form: NgForm) {
    this.submitted = true;

    if (form.valid) {
      if (isNaN(this.revenu.montant) || this.revenu.montant > 999999) {
        let alertSuccess = await this.alertCtrl.create({
          header: 'Erreur !',
          subHeader: 'Montant incorrect',
          buttons: [{text: 'OK'}]
        });
        return alertSuccess.present();
      }

      if (this.revenu.montant < 0) {
        const alertSuccess = await this.alertCtrl.create({
          header: 'Signup...',
          subHeader: 'le montant doit être positif',
          buttons: [{text: 'OK'}]
        });
        return alertSuccess.present();
      }
      if (typeof this.revenu.poste === "undefined") {
        const alertSuccess = await this.alertCtrl.create({
          header: 'Signup...',
          subHeader: 'le poste budgetaire est obligatoire',
          buttons: [{text: 'OK'}]
        });
        alertSuccess.present();
        return;
      }

      if (typeof this.revenu.libelle === "undefined") {
        let alertSuccess = await this.alertCtrl.create({
          header: 'Signup...',
          subHeader: 'le libelle est obligatoire',
          buttons: ['OK']
        });
        alertSuccess.present();
        return;
      }

      if (this.revenu.frequence != 'OUI' && this.revenu.frequence != 'NON') {
        let alertSuccess = await this.alertCtrl.create({
          header: 'Signup...',
          subHeader: 'La fréquence doit être égale à OUI ou NON',
          buttons: ['OK']
        });
        alertSuccess.present();
        return;
      }


      this.loading = await this.loadingCtrl.create({
        message: "Vérification en cours ..."
      });
      this.loading.present();
      //this.signup.montant = this.signup.montant * (-1);
      let datain = JSON.stringify({
        "code_recurent": this.revenu.frequence,
        "id_poste_budgetaire": this.revenu.poste,
        "montant": this.revenu.montant,
        "libelle": this.revenu.libelle
      });
      this.http.post('https://www.smartbudget-udaf03.fr/api/mouvements_create?api_token=' + this.apitoken, datain)
        .subscribe(
          () => {
            this.getRevenue();
            this.modalController.dismiss(true);
          },
          error => {
            this.loading.dismiss();
            alert('Probleme de connexion : ');
            console.log(error);
            this.modalController.dismiss(null);
          }
        );
    }
  }
}
