import { Component } from '@angular/core';
import { UserData } from '../../providers/user-data';

@Component({
  selector: 'page-mentions',
  templateUrl: 'mentions.html',
  styleUrls: ['./mentions.scss']
})

export class MentionsPage {

  constructor(public userData: UserData) { }

}
