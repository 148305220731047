<ion-header>
  <ion-toolbar color="ui">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
      <ion-menu-toggle>
        <ion-button>
          <ion-icon name="menu"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
    <ion-title>Questions & Coordonnées</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="card-img">
    <img src="assets/img/questions.png" alt=""/>
  </div>
  <ion-card>
    <ion-card-content>
      <ion-card-title class="title" color="ui">
        <h1>En cas de soucis, c’est ici !</h1>
      </ion-card-title>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <p>Cette application a pour but de vous donner un certain nombre de réponses et de pistes de réflexion pour vous aider à gérer au mieux votre budget.</p>
      <p>Si toutefois vous ne trouviez pas la solution ici, vous pouvez contacter l’UDAF en nous envoyant un mail grâce au bouton ci-dessous ou en contactant le Point Info Famille, service d’information sur tous les sujets de la vie quotidienne : </p>

      <ion-button fill="clear" color="ui" onclick="window.open('mailto:contact@udaf03.fr', '_system')">
        <ion-icon slot="start" name="mail"></ion-icon>
        Envoyer un mail
      </ion-button>
      <ion-button fill="clear" color="ui" onclick="window.open('tel:0470487065', '_system')">
        <ion-icon slot="start" name="call"></ion-icon>
        04 70 48 70 65
      </ion-button>

    </ion-card-content>
  </ion-card>

  <ion-card color="astuces" class="card-astuces">
    <ion-card-header>
      <ion-item class="ion-no-padding" color="astuces">
        <ion-icon name="link" slot="start" size="large"></ion-icon>
        Liens utiles
      </ion-item>
    </ion-card-header>
    <ion-card-content>
      <ion-button size="small" onclick="window.open('http://www.jeunes.gouv.fr/', '_system', 'location=yes')">
        <ion-icon slot="start" name="open-outline"></ion-icon>
        Jeunes.gouv.fr
      </ion-button>
      <ion-button size="small"
              onclick="window.open('http://www.infofamille03.org', '_system', 'location=yes')">
        <ion-icon slot="start" name="open-outline"></ion-icon>
        Point Info Famille 03
      </ion-button>
    </ion-card-content>
  </ion-card>

</ion-content>
