import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { UserData } from '../../providers/user-data';
import { UserOptions } from '../../interfaces/user-options';

import { AlertController, LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { MentionsPage } from '../mentions/mentions';
import { LoginPage } from '../login/login';
import { AccountPage } from '../account/account';

@Component({
  selector: 'page-user',
  templateUrl: 'signup.html',
  styleUrls: ['signup.scss']
})
export class SignupPage {

  signup: UserOptions = {username: '', password: '', name: '', soldeinit: 0, accepted: false};
  submitted = false;
  data: any;

  constructor(public userData: UserData, public http: HttpClient, public alertCtrl: AlertController, public loadingCtrl: LoadingController) {
  }

  async onSignup(form: NgForm) {
    this.submitted = true;

    if (form.valid) {

      if (isNaN(this.signup.soldeinit) || this.signup.soldeinit > 9999999) {
        let alertSuccess = await this.alertCtrl.create({
          header: 'Solde initial',
          subHeader: 'Montant incorrect',
          buttons: [{text: 'OK'}]
        });
        return alertSuccess.present();
      }

      if (this.signup.accepted == false) {
        let alertSuccess = await this.alertCtrl.create({
          subHeader: 'Votre consentement est obligatoire',
          buttons: [{text: 'OK'}]
        });
        return alertSuccess.present();
      }

      var regex = /^[a-zA-Z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/;
      if (!regex.test(this.signup.username)) {
        let alertSuccess = await this.alertCtrl.create({
          header: 'Nom',
          subHeader: 'E-mail incorrect',
          buttons: [{text: 'OK'}]
        });
        alertSuccess.present();
      } else {
        const loading = await this.loadingCtrl.create({
          message: "Vérification en cours ..."
        });
        loading.present();


        let datain = JSON.stringify({
          "email": this.signup.username,
          "password": this.signup.password,
          "name": this.signup.name,
          "soldeinit": this.signup.soldeinit
        });
        this.http.post('https://www.smartbudget-udaf03.fr/api/creation_user', datain).subscribe(
          async (data) => {
            this.data = data;
            if (typeof this.data.api_token !== "undefined") {
              loading.dismiss();
              this.userData.signup(this.signup.username, this.data.api_token);
              return this.userData.getNav().setRoot(AccountPage);
            } else {
              let alertSuccess = await this.alertCtrl.create({
                subHeader: 'E-mail existant ou incorrect',
                buttons: [{text: 'OK'}]
              });
              loading.dismiss();
              alertSuccess.present();
            }
          },
          async (error) => {
            let alertSuccess = await this.alertCtrl.create({
              header: error,
              subHeader: 'Aucune connexion à internet',
              buttons: [{text: 'OK'}]
            });
            loading.dismiss();
            alertSuccess.present();
          }
        );

      }
    }
  }

  goToMentions() {
    this.userData.getNav().setRoot(MentionsPage);
  }

  goToLogin() {
    this.userData.getNav().setRoot(LoginPage);
  }

}
