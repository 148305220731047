<ion-header>
  <ion-toolbar color="ui">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
      <ion-menu-toggle>
        <ion-button>
          <ion-icon name="menu"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
    <ion-title>Connexion</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-card>
    <ion-card-content>
      <ion-card-title color="ui">Connecte-toi !</ion-card-title>
      <form #loginForm="ngForm" novalidate>
        <ion-list lines="no">
          <ion-item class="ion-padding" color="gris0">
            <ion-label position="floating">Mail</ion-label>
            <ion-input [(ngModel)]="login.username" name="username" type="email" #username="ngModel" spellcheck="false"
                       autocapitalize="off" required></ion-input>
          </ion-item>

          <p [hidden]="username.valid || submitted == false" class="ion-padding-left"><ion-text class="error-message" color="danger">Mail obligatoire</ion-text></p>

          <ion-item class="ion-padding" color="gris0">
            <ion-label position="floating">Mot de passe</ion-label>
            <ion-input [(ngModel)]="login.password" name="password" type="password" #password="ngModel"
                       required></ion-input>
          </ion-item>

          <p [hidden]="password.valid || submitted == false" class="ion-padding-left">
            <ion-text color="danger" class="error-message">Mot de passe obligatoire</ion-text>
          </p>

        </ion-list>

        <div class="ion-padding">
          <ion-button expand="block" color="secondary" (click)="onLogin(loginForm)" type="submit">Se connecter</ion-button>
        </div>
      </form>

    </ion-card-content>
  </ion-card>

  <p class="ion-text-center ion-padding">
    <a class="link" (click)="onSignup()">Je n'ai pas encore de compte</a><br/>
  </p>
  <p class="ion-text-center">
    <a class="link" (click)="onLosepassword()">J'ai oublié mon mot de passe</a>
  </p>

</ion-content>
