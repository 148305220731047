import { Component } from '@angular/core';

import { UserData } from '../../providers/user-data';

import { RevenusPage } from '../revenus/revenus';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { DepensesPage } from '../depenses/depenses';
import { LoginPage } from '../login/login';

@Component({
  selector: 'page-account',
  templateUrl: 'account.html',
  styleUrls: ['account.scss']
})

export class AccountPage {
  username: string;
  apitoken: string;
  cumulsolde: any;
  mvts: any;
  solde: any;
  solde_fin_de_mois: any;
  solde_debut_de_mois: any;
  data: any;

  constructor(
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public http: HttpClient,
    public alertCtrl: AlertController,
    public userData: UserData
  ) {
  }

  async presentLoading(): Promise<HTMLIonLoadingElement> {
    const loading: HTMLIonLoadingElement = await this.loadingCtrl.create({
      message: "Chargement en cours ..."
    });

    await loading.present();

    return loading;
  }

  ngOnInit() {
    this.getUsername();
    this.userData.getUserapitoken().then((apitoken: string) => {

      this.presentLoading();

      this.apitoken = apitoken;

      if (this.apitoken) {
        this.getSolde();
      } else {
        this.loadingCtrl.dismiss();
        this.userData.getNav().setRoot(LoginPage);
      }
    });
  }

  getSolde() {
    this.cumulsolde = 0;
    this.http.get('https://www.smartbudget-udaf03.fr/api/mouvements_mois_en_cours?api_token=' + this.apitoken)
      .subscribe(
        datasolde => {
          this.mvts = datasolde;
          for (var i = 0; i < this.mvts.length; i++) {
            this.cumulsolde = parseFloat(this.cumulsolde) + parseFloat(this.mvts[i].montant);

          }
          this.http.get('https://www.smartbudget-udaf03.fr/api/solde_mois_precedent?api_token=' + this.apitoken)
            .subscribe(
              datasoldep => {
                this.solde = datasoldep;
                this.solde_fin_de_mois = (parseFloat(this.cumulsolde) + parseFloat(this.solde[0].nouveau_solde)).toFixed(2);
                this.solde_debut_de_mois = this.solde[0].nouveau_solde;
                this.loadingCtrl.dismiss();
              },
              error => {
                this.loadingCtrl.dismiss();
                console.log(error);
                alert('Aucune connexion à internet');
                return;
              }
            );
        },
        error => {
          this.loadingCtrl.dismiss();
          console.log(error);
          alert('Aucune connexion à internet');
          return;
        }
      );
  }

  async createDepense() {
    const modal = await this.modalCtrl.create({
      component: DepensesPage,
      showBackdrop: true,
      swipeToClose: true,

    });

    modal.onDidDismiss().then(({data}) => {
      if (data !== null) {
        this.getSolde()
      }
    });

    return await modal.present();
  }

  async createRevenu() {
    const modal = await this.modalCtrl.create({
      component: RevenusPage,
      showBackdrop: true,
      swipeToClose: true
    });

    modal.onDidDismiss().then(({data}) => {
      if (data !== null) {
        this.getSolde()
      }
    });

    return await modal.present();
  }

  async suppMouvement($id) {
    const alertsupp = await this.alertCtrl.create({
      header: 'Supprimer',
      message: 'Tu veux vraiment supprimer cette ligne ?',
      buttons: [
        {text: 'Non'},
        {
          text: 'Oui',
          handler: () => {
            this.http.get('https://www.smartbudget-udaf03.fr/api/mouvements_delete/' + $id + '?api_token=' + this.apitoken)
              .subscribe(
                () => this.getSolde(),
                error => {
                  console.log(error);
                  alert('Aucune connexion à internet');
                }
              );
          }
        }
      ]
    });

    alertsupp.present();
  }

  async modifMouvement(id, montant) {
    let signe = '+';
    if (montant < 0) {
      signe = '-';
      montant = (montant * (-1));
    }

    let alertmodif = await this.alertCtrl.create({
      header: 'Modifier',
      buttons: [
        {text: 'Non'},
        {
          text: 'Oui',
          handler: (data: any) => {
            //alert(data.montant.length);
            if (isNaN(data.montant) || data.montant.length > 10) {
              alert('montant incorrect');
            } else {
              if (signe == '-') {
                data.montant = (data.montant * (-1));
              }
              let datain = JSON.stringify({"montant": data.montant, "id": id});

              this.http.post('https://www.smartbudget-udaf03.fr/api/mouvements_update?api_token=' + this.apitoken, datain)
                .subscribe(
                  data => {
                    this.data = data;
                    this.getSolde();
                  },
                  error => {
                    console.log(error);
                    alert('Aucune connexion à internet');
                  }
                );
            }
          }
        }
      ],
      inputs: [{
        name: 'montant',
        value: montant,
        placeholder: 'montant'
      }]
    });
    alertmodif.present();
  }

  getUsername() {
    this.userData.getUsername().then((username) => {
      this.username = username;
    });
  }
}
