import { Component } from '@angular/core';
import { UserData } from '../../providers/user-data';

import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { AlertController, LoadingController } from '@ionic/angular';
import { LoginPage } from '../login/login';
import { AccountPage } from '../account/account';
import { QuestionsPage } from '../questions/questions';
import { ExternalPage } from '../external/external';
import { IPage, ISearchPage } from '../../models/search-page.interface';

@Component({
  selector: 'page-accueil',
  templateUrl: 'accueil.html',
  styleUrls: ['accueil.scss']
})
export class AccueilPage {
  username: string;
  apitoken: string;
  cumulsolde: any;
  mvts: any;
  solde: any;
  solde_fin_de_mois: any;
  solde_debut_de_mois: any;
  pages: ISearchPage[];

  constructor(public http: HttpClient, public storage: Storage, public userData: UserData, public alertCtrl: AlertController, public loadingCtrl: LoadingController) {
  }

  ngOnInit() {
    this.username = null;
    this.apitoken = null;
    this.storage.get('apitoken').then(async (apitoken) => {
      let loading = await this.loadingCtrl.create({
        message: "Chargement en cours ..."
      });

      loading.present();
      this.apitoken = apitoken;
      if (this.apitoken) {
        this.cumulsolde = 0;
        this.http.get('https://www.smartbudget-udaf03.fr/api/mouvements_mois_en_cours?api_token=' + this.apitoken)
          .subscribe(
            data => {
              this.mvts = data;
              for (var i = 0; i < this.mvts.length; i++) {
                this.cumulsolde = parseFloat(this.cumulsolde) + parseFloat(this.mvts[i].montant);
              }
              this.http.get('https://www.smartbudget-udaf03.fr/api/solde_mois_precedent?api_token=' + this.apitoken)
                .subscribe(
                  data => {
                    this.solde = data;
                    this.solde_fin_de_mois = (parseFloat(this.cumulsolde) + parseFloat(this.solde[0].nouveau_solde)).toFixed(2);
                    this.solde_debut_de_mois = this.solde[0].nouveau_solde;
                    loading.dismiss();
                  },
                  error => {
                    loading.dismiss();
                    console.log(error);
                    alert('Aucune connexion à internet');
                    return;
                  }
                );
            },
            error => {
              loading.dismiss();
              console.log(error);
              alert('Aucune connexion à internet');
              return;
            }
          );
      } else {
        loading.dismiss()
      }
    });

    this.http.get('https://appli.smartbudget-udaf03.fr/wp-json/wp/v2/pages?orderby=menu_order&order=asc')
      .subscribe((res: ISearchPage[]) => this.pages = res);
  }

  goToQuestions() {
    this.userData.getNav().push(QuestionsPage);
  }

  goToAccount() {
    this.userData.getNav().push(AccountPage);
  }

  goToLogin() {
    this.userData.getNav().push(LoginPage);
  }

  goToPage($event: IPage) {
    this.userData.updatePage($event);
    this.userData.getNav().push(ExternalPage);
  }
}
