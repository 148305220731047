<ion-header>
  <ion-toolbar color="budget">
    <ion-title>Mes dépenses</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="depenses-page">

  <ion-card color="gris0">
    <ion-card-content>
      <ion-card-title color="ui">Nouvelle dépense</ion-card-title>
      <form #depenseForm="ngForm" novalidate>
        <ion-list>
          <ion-item color="gris0" class="ion-padding">
            <ion-label position="floating" color="primary">Tous les mois</ion-label>
            <ion-select [(ngModel)]="depense.frequence" name="frequence" #frequence="ngModel" type="text" required>
              <ion-select-option selected="true" value="NON">NON</ion-select-option>
              <ion-select-option value="OUI">OUI</ion-select-option>
            </ion-select>
          </ion-item>
          <p [hidden]="frequence.valid || submitted == false" class="ion-padding-left">
            <ion-text color="danger">Fréquence obligatoire</ion-text>
          </p>

          <ion-item color="gris0" class="ion-padding">
            <ion-label position="floating" color="primary">Poste budgétaire</ion-label>
            <ion-select [(ngModel)]="depense.poste" name="poste" #poste="ngModel" type="text" required>
              <ion-select-option *ngFor="let depense of depenses"
                                 value="{{depense.id}}">{{depense.nom}}</ion-select-option>
            </ion-select>
          </ion-item>
          <p [hidden]="poste.valid || submitted == false" class="ion-padding-left">
            <ion-text color="danger">Poste budgétaire obligatoire</ion-text>
          </p>

          <ion-item color="gris0" class="ion-padding">
            <ion-label position="floating" color="primary">Libellé</ion-label>
            <ion-input [(ngModel)]="depense.libelle" name="libelle" type="text" #libelle="ngModel" required>
            </ion-input>
          </ion-item>
          <p [hidden]="libelle.valid || submitted == false" class="ion-padding-left">
            <ion-text color="danger">Libellé obligatoire</ion-text>
          </p>

          <ion-item color="gris0" class="ion-padding">
            <ion-label position="floating" color="primary">Montant</ion-label>
            <ion-input [(ngModel)]="depense.montant" name="montant" type="number" #montant="ngModel" required>
            </ion-input>
          </ion-item>
          <p [hidden]="montant.valid || submitted == false" class="ion-padding-left">
            <ion-text color="danger">Le montant doit être un nombre positif</ion-text>
          </p>
        </ion-list>
        <ion-row>
          <ion-col>
            <ion-button color="secondary" (click)="onDepense(depenseForm)" type="submit" expand="block">Valider</ion-button>
          </ion-col>
          <ion-col>
            <ion-button color="gris2" (click)="modalController.dismiss(null)" expand="block">Annuler</ion-button>
          </ion-col>
        </ion-row>
      </form>
    </ion-card-content>
  </ion-card>

</ion-content>
