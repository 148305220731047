<ion-header>
	<ion-toolbar color="ui">
		<ion-buttons slot="start">
			<ion-back-button></ion-back-button>
			<ion-menu-toggle>
				<ion-button>
					<ion-icon name="menu"></ion-icon>
				</ion-button>
			</ion-menu-toggle>
		</ion-buttons>
		<ion-title>Créer un compte</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content class="login-page">

	<ion-card>
	  <ion-card-content>
	    <ion-card-title color="primary">Apprends à maîtriser ton budget et informe-toi !</ion-card-title>
			<form #signupForm="ngForm" novalidate>
			<ion-list>
				<ion-item color="gris0" class="ion-padding">
					<ion-label position="floating" color="primary">Nom</ion-label>
					<ion-input [(ngModel)]="signup.name" name="name" type="text" #name="ngModel" required>
					</ion-input>
				</ion-item>
				<p [hidden]="name.valid || submitted == false" class="error-message">
					<ion-text color="danger">Nom obligatoire</ion-text>
				</p>

				<ion-item color="gris0" class="ion-padding">
					<ion-label position="floating" color="primary">Mail</ion-label>
					<ion-input [(ngModel)]="signup.username" name="username" type="email" #username="ngModel" required>
					</ion-input>
				</ion-item>
				<p [hidden]="username.valid || submitted == false" class="error-message">
					<ion-text color="danger">Mail obligatoire</ion-text>
				</p>

				<ion-item color="gris0" class="ion-padding">
					<ion-label position="floating" color="primary">Mot de passe</ion-label>
					<ion-input [(ngModel)]="signup.password" name="password" type="password" #password="ngModel" required minlength=6>
					</ion-input>
				</ion-item>
				<p [hidden]="password.valid || submitted == false" class="error-message">
					<ion-text color="danger">Le mot de passe doit contenir entre 6 et 10 caractères</ion-text>
				</p>
				<ion-item color="gris0" class="ion-padding">
					<ion-label position="floating" color="primary">Solde initial</ion-label>
					<ion-input [(ngModel)]="signup.soldeinit" name="soldeinit" type="number" #soldeinit="ngModel" required>
					</ion-input>
				</ion-item>
				<p [hidden]="soldeinit.valid || submitted == false" class="error-message">
					<ion-text color="danger">Le solde doit être un nombre</ion-text>
				</p>

				<ion-card>
					<ion-card-content>
						<p>En soumettant ce formulaire, j'accepte que les informations saisies soient utilisées dans le cadre de l'application Smart'Budget, pour la gestion de mon budget.</p>
						<p>Vous disposez de droits Informatique et Libertés sur les données vous concernant, que vous pouvez exercer en adressant une demande par mail à contact@udaf03.fr.</p>
						<p><ion-button (click)="goToMentions()" size="small" color="budget">En savoir plus</ion-button></p>
						<ion-item class="ion-no-padding ion-padding-top">
							<ion-label>J'accepte</ion-label>
							<ion-checkbox slot="start" class="ion-no-padding" [(ngModel)]="signup.accepted" name="accepted" #accepted="ngModel" required></ion-checkbox>
						</ion-item>
						<p [hidden]="accepted.valid || submitted == false" class="ion-padding-left">
							<ion-text color="danger">Votre consentement est obligatoire</ion-text>
						</p>
					</ion-card-content>
				</ion-card>

			</ion-list>

			<div class="ion-padding">
				<ion-button color="secondary" (click)="onSignup(signupForm)" type="submit" expand="block">Valider</ion-button>
			</div>
		</form>

	  </ion-card-content>
	</ion-card>

	<p class="ion-text-center ion-padding"><a class="link" (click)="goToLogin()">J'ai déjà un compte</a></p>

</ion-content>
