<ion-split-pane contentId="app">

  <!-- logged out menu -->
  <ion-menu side="start" menuId="loggedOutMenu" contentId="app">

    <ion-content class="outer-content">

      <ion-list class="color-menu">
        <ion-list-header color="gris1">
          <ion-label>Smart'Budget</ion-label>
        </ion-list-header>
        <ion-item button menuClose *ngFor="let p of appPages" (click)="openPage(p)">
          <ion-icon *ngIf="p.title === 'Accueil'" slot="start" [name]="p.icon" [color]="p.color"></ion-icon>
          <ion-icon *ngIf="p.title !== 'Accueil'" slot="start" [src]="p.icon" [style.color]="p.color"></ion-icon>
          <ion-text [innerHTML]="p.title"></ion-text>
        </ion-item>
      </ion-list>

      <ion-list class="color-menu">
        <ion-list-header color="gris1">
          <ion-label>Mon compte</ion-label>
        </ion-list-header>
        <ion-item button menuClose *ngFor="let p of loggedOutPages" (click)="openPage(p)">
          <ion-icon slot="start" [name]="p.icon" [color]="active === p.name ? 'primary' : ''"></ion-icon>
          {{p.title}}
        </ion-item>
      </ion-list>

      <ion-list class="color-menu">
        <ion-list-header color="gris1">
          <ion-label>Informations</ion-label>
        </ion-list-header>
        <ion-item button menuClose *ngFor="let p of infoPages" (click)="openPage(p)">
          <ion-icon slot="start" [name]="p.icon" [color]="active === p.name ? 'primary' : ''"></ion-icon>
          {{p.title}}
        </ion-item>
      </ion-list>

    </ion-content>

  </ion-menu>

  <!-- logged in menu -->
  <ion-menu side="start" menuId="loggedInMenu" contentId="app">

    <ion-content class="outer-content">

      <ion-list class="color-menu">
        <ion-list-header color="gris1">
          <ion-label>Smart'Budget</ion-label>
        </ion-list-header>

        <ion-item button menuClose *ngFor="let p of appPages" (click)="openPage(p)">
          <ion-icon *ngIf="p.title === 'Accueil'" slot="start" [name]="p.icon" [color]="p.color"></ion-icon>
          <ion-icon *ngIf="p.title !== 'Accueil'" slot="start" [src]="p.icon" [style.color]="p.color"></ion-icon>
          <ion-text [innerHTML]="p.title"></ion-text>
        </ion-item>
      </ion-list>

      <ion-list class="color-menu">
        <ion-list-header color="gris1">
          <ion-label>Mon compte</ion-label>
        </ion-list-header>
        <ion-item button menuClose *ngFor="let p of loggedInPages" (click)="openPage(p)">
          <ion-icon slot="start" [name]="p.icon" [color]="active === p.name ? 'primary' : ''"></ion-icon>
          <ion-text>{{p.title}}</ion-text>
        </ion-item>
      </ion-list>

      <ion-list class="color-menu">
        <ion-list-header color="gris1">
          <ion-label>Informations</ion-label>
        </ion-list-header>
        <ion-item button menuClose *ngFor="let p of infoPages" (click)="openPage(p)">
          <ion-icon slot="start" [name]="p.icon" [color]="active === p.name ? 'primary' : ''"></ion-icon>
          <ion-text [innerHTML]="p.title"></ion-text>
        </ion-item>
      </ion-list>

    </ion-content>

  </ion-menu>

  <!-- main navigation -->
  <ion-nav #nav [root]="rootPage" id="app" swipeGesture="false"></ion-nav>

</ion-split-pane>
