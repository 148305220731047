import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { UserData } from '../../providers/user-data';

import { DepenseOptions } from '../../interfaces/depense-options';

import { Storage } from '@ionic/storage';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'page-depenses',
  templateUrl: 'depenses.html',
  styleUrls: ['depenses.scss']
})
export class DepensesPage {
  depense: DepenseOptions = {frequence: '', libelle: '', poste: '', montant: 0};
  submitted = false;
  apitoken: any;
  depenses: any;
  data: any;

  constructor(public modalController: ModalController, public storage: Storage, public userData: UserData, public http: HttpClient, public alertCtrl: AlertController, public loadingCtrl: LoadingController) {
  }

  ngOnInit() {
    this.depense.frequence = 'NON';
    this.storage.get('apitoken').then(async (apitoken) => {
      let loading = await this.loadingCtrl.create({
        message: "Chargement en cours ..."
      });
      loading.present();
      this.apitoken = apitoken;
      if (this.apitoken) {
        this.http.get('https://www.smartbudget-udaf03.fr/api/postes_depense?api_token=' + this.apitoken).subscribe(
          data => {
            loading.dismiss();
            this.depenses = data;
            this.depense.poste = this.depenses[0].id;
          },
          error => {
            loading.dismiss();
            console.log(error);
            alert(error);
            return;
          }
        );
      } else {
        loading.dismiss();
      }
    });
  }

  async onDepense(form: NgForm) {
    this.submitted = true;
    //this.depense.frequence = 'OUI';

    if (form.valid) {
      if (isNaN(this.depense.montant) || this.depense.montant > 999999) {
        let alertSuccess = await this.alertCtrl.create({
          header: 'Erreur !',
          subHeader: 'Montant incorrect',
          buttons: [{text: 'OK'}]
        });
        alertSuccess.present();
        return;
      }

      if (this.depense.montant < 0) {
        let alertSuccess = await this.alertCtrl.create({
          header: 'Signup...',
          subHeader: 'le montant doit être positif',
          buttons: [{text: 'OK'}]
        });
        alertSuccess.present();
        return;
      }
      if (typeof this.depense.poste === "undefined") {
        let alertSuccess = await this.alertCtrl.create({
          header: 'Signup...',
          subHeader: 'le poste budgetaire est obligatoire',
          buttons: [{text: 'OK'}]
        });
        alertSuccess.present();
        return;
      }

      if (typeof this.depense.libelle === "undefined") {
        let alertSuccess = await this.alertCtrl.create({
          header: 'Signup...',
          subHeader: 'le libelle est obligatoire',
          buttons: [{text: 'OK'}]
        });
        alertSuccess.present();
        return;
      }

      if (this.depense.frequence != 'OUI' && this.depense.frequence != 'NON') {
        let alertSuccess = await this.alertCtrl.create({
          header: 'Signup...',
          subHeader: 'La fréquence doit être égale à OUI ou NON',
          buttons: [{text: 'OK'}]
        });
        alertSuccess.present();
        return;
      }

      let loading = await this.loadingCtrl.create({
        message: "Vérification en cours ..."
      });
      loading.present();
      //this.signup.montant = this.signup.montant * (-1);
      let datain = JSON.stringify({
        "code_recurent": this.depense.frequence,
        "id_poste_budgetaire": this.depense.poste,
        "montant": this.depense.montant * (-1),
        "libelle": this.depense.libelle
      });
      this.http.post('https://www.smartbudget-udaf03.fr/api/mouvements_create?api_token=' + this.apitoken, datain)
        .subscribe(
          data => {
            loading.dismiss();
            this.modalController.dismiss(true);
          },
          error => {
            loading.dismiss();
            this.modalController.dismiss(null);
            alert('Probleme de connexion : ' + error);
          }
        );
    }
  }
}
