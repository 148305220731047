import { Component, OnInit } from '@angular/core';
import { UserData } from '../../providers/user-data';
import { IPage } from '../../models/search-page.interface';


@Component({
  selector: 'external-page',
  templateUrl: 'external.html',
  styleUrls: ['external.scss']
})
export class ExternalPage implements OnInit {
  page: IPage;

  constructor(
    private userData: UserData,
  ) {}

  url = null;

  ngOnInit(): void {
    this.page = this.userData.page;
    const anchor = this.page.anchor !== '' ? '#' + this.page.anchor : '';
    this.url = 'https://appli.smartbudget-udaf03.fr/' + this.page.slug + anchor;
  }
}
