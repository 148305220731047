import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { UserData } from '../../providers/user-data';

import { LoginOptions } from '../../interfaces/login-options';
import { SignupPage } from '../signup/signup';
import { AccountPage } from '../account/account';
import { LosepasswordPage } from '../losepassword/losepassword';
import { AlertController, AlertOptions, LoadingController, LoadingOptions } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'page-user',
  templateUrl: 'login.html',
  styleUrls: ['login.scss']
})

export class LoginPage {

  login: LoginOptions = {username: '', password: ''};
  submitted = false;
  data: any;

  constructor(public userData: UserData, public http: HttpClient, public alertCtrl: AlertController, public loadingCtrl: LoadingController) {
  }

  loadingPresent(options: LoadingOptions): Promise<HTMLIonLoadingElement> {
    return this.loadingCtrl.create(options);
  }

  alertPresent(options: AlertOptions): Promise<HTMLIonAlertElement> {
    return this.alertCtrl.create(options);
  }

  async onLogin(form: NgForm) {
    this.submitted = true;

    if (form.valid) {
      const regex = /^[a-zA-Z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/;

      if (!regex.test(this.login.username)) {
        const alertSuccess = await this.alertPresent({
          message: 'Problème !',
          subHeader: 'E-mail incorrect',
          buttons: ['OK']
        });
        return alertSuccess.present();
      }

      const loading = await this.loadingPresent({
        message: "Vérification en cours ..."
      });

      loading.present();

      let datain = JSON.stringify({"email": this.login.username, "password": this.login.password});

      this.http.post('https://www.smartbudget-udaf03.fr/api/login', datain).subscribe(
        async (data) => {
          this.data = data;

          if (typeof this.data.api_token !== "undefined") {
            loading.dismiss();
            this.userData.login(this.login.username, this.data.api_token);
            //this.userData.getNav().setRoot(AccountPage, {token:this.data.api_token, user:this.login.username});
            this.userData.getNav().setRoot(AccountPage);
          } else {
            const alertSuccess = await this.alertPresent({
              header: 'Login...',
              subHeader: 'Mail ou mot de passe incorrect',
              buttons: ['OK']
            });
            loading.dismiss();
            alertSuccess.present();
          }
        },

        async (error) => {
          let alertSuccess = await this.alertCtrl.create({
            header: 'Problème !',
            subHeader: 'Aucune connexion à internet',
            buttons: ['OK']
          });
          console.log(error);
          loading.dismiss();
          alertSuccess.present();
        }
      );


    }
  }

  onSignup() {
    this.userData.getNav().push(SignupPage);
  }

  onLosepassword() {
    this.userData.getNav().push(LosepasswordPage);
  }
}
