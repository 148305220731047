import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full'
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'accueil',
    loadChildren: () => import('./pages/accueil/accueil.module').then(m => m.AccueilModule)
  },
  {
    path: ':external',
    loadChildren: () => import('./pages/external/external.module').then(m => m.ExternalModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'questions',
    loadChildren: () => import('./pages/questions/questions.module').then(m => m.QuestionsModule)
  },
  {
    path: 'mentions',
    loadChildren: () => import('./pages/mentions/mentions.module').then(m => m.MentionsModule)
  },
  {
    path: 'histo',
    loadChildren: () => import('./pages/histo/histo.module').then(m => m.HistoModule)
  },
  {
    path: 'losepassword',
    loadChildren: () => import('./pages/losepassword/losepassword.module').then(m => m.LosepasswordModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{provide: APP_BASE_HREF, useValue: ''}],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
