<ion-header>
  <ion-toolbar color="budget">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
      <ion-menu-toggle>
        <ion-button>
          <ion-icon name="menu"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
    <ion-title>Historique</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="ion-text-wrap ion-padding" *ngIf="doughnutAll.length == 0">
    <h6>Aucun historique pour le moment</h6>
    <p>Chaque mois terminé sera sauvegardé dans ton historique des dépenses.</p>
    <p>
      <ion-button (click)="goToAccount()" color="budget">J'enregistre mes mouvements</ion-button>
    </p>
  </div>

  <ion-card-title *ngIf="doughnutAll.length != 0" class="ion-padding" color="ui">Historique de mes dépenses
  </ion-card-title>

  <ion-card class="ion-no-padding" *ngFor="let item of doughnutAll">
    <ion-card-header class="accordeon-title" id="p-{{item.ind}}"
                     (click)="Accordeon(item.ind)">{{item.periode}} : {{item.cumul}} €
      <ion-icon id="i-{{item.ind}}" class="udaf-add"></ion-icon>
    </ion-card-header>
    <ion-card-content class="ion-padding accordeon-content" id="e-{{item.ind}}">

      <p>
        <canvas height="300" baseChart
                [colors]="item.doughnutChartColors"
                [data]="item.doughnutChartData"
                [labels]="item.doughnutChartLabels"
                [options]="doughnutChartOptions"
                [chartType]="doughnutChartType"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)">
        </canvas>
      </p>
    </ion-card-content>
  </ion-card>

  <p class="ion-text-center username">historique des dépenses de {{username}}</p>

</ion-content>
