<ion-header>
  <ion-toolbar color="ui">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
      <ion-menu-toggle>
        <ion-button>
          <ion-icon name="menu"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
    <ion-title>Mot de passe perdu</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-card color="gris0">
    <ion-card-content>
      <ion-card-title class="title" color="ui">Un oubli ?</ion-card-title>
      <p>Saisis ton mail et valide, pour lancer la procédure de récupération de ton mot de passe.</p>
      <form #loginForm="ngForm" novalidate>
        <ion-list lines="no">
          <ion-item color="gris0" class="ion-padding">
            <ion-label position="floating" color="primary">Mail</ion-label>
            <ion-input [(ngModel)]="login.username" name="username" type="email" #username="ngModel" spellcheck="false"
                       autocapitalize="off" required></ion-input>
          </ion-item>
          <p class="error-message" [hidden]="username.valid || submitted == false"><ion-text color="danger">Mail obligatoire</ion-text></p>
        </ion-list>
        <div class="ion-padding">
          <ion-button expand="block" color="secondary" (click)="onLogin(loginForm)" type="submit">Valider</ion-button>
        </div>
      </form>
    </ion-card-content>
  </ion-card>

</ion-content>
