<ion-header>
  <ion-toolbar color="ui">
    <ion-title>Mes revenus</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="revenus-page">

  <ion-card color="gris0">
    <ion-card-content>
      <ion-card-title color="ui">Nouveau revenu</ion-card-title>
      <form #revenuForm="ngForm" novalidate>
        <ion-list>
          <ion-item color="gris0" class="ion-padding">
            <ion-label position="floating" color="primary">Tous les mois</ion-label>
            <ion-select [(ngModel)]="revenu.frequence" name="frequence" #frequence="ngModel" type="text" required>
              <ion-select-option value="NON">NON</ion-select-option>
              <ion-select-option value="OUI">OUI</ion-select-option>
            </ion-select>
          </ion-item>
          <p [hidden]="frequence.valid || submitted == false">
            <ion-text  class="ion-padding-left" color="danger">Fréquence obligatoire</ion-text>
          </p>

          <ion-item color="gris0" class="ion-padding">
            <ion-label position="floating" color="primary">Poste budgétaire</ion-label>
            <ion-select [(ngModel)]="revenu.poste" name="poste" #poste="ngModel" type="text" required>
              <ion-select-option *ngFor="let monrevenu of revenus" [value]="monrevenu.id">{{monrevenu.nom}}</ion-select-option>
            </ion-select>
          </ion-item>
          <p [hidden]="poste.valid || submitted == false">
            <ion-text class="ion-padding-left" color="danger">Poste budgétaire obligatoire</ion-text>
          </p>

          <ion-item color="gris0" class="ion-padding">
            <ion-label position="floating" color="primary">Libellé</ion-label>
            <ion-input [(ngModel)]="revenu.libelle" name="libelle" type="text" #libelle="ngModel" required>
            </ion-input>
          </ion-item>
          <p [hidden]="libelle.valid || submitted == false" class="ion-padding-left">
            <ion-text color="danger">Libellé obligatoire</ion-text>
          </p>

          <ion-item color="gris0" class="ion-padding">
            <ion-label position="floating" color="primary">Montant</ion-label>
            <ion-input [(ngModel)]="revenu.montant" name="montant" type="number" #montant="ngModel" required>
            </ion-input>
          </ion-item>
          <p [hidden]="montant.valid || submitted == false">
            <ion-text color="danger" class="ion-padding-left">Le montant doit être un nombre positif</ion-text>
          </p>
        </ion-list>

        <ion-row>
          <ion-col>
            <ion-button color="secondary" (click)="onRevenu(revenuForm)" type="submit" expand="block">Valider</ion-button>
          </ion-col>
          <ion-col>
            <ion-button color="gris2" (click)="modalController.dismiss(null)" expand="block">Annuler</ion-button>
          </ion-col>

        </ion-row>
      </form>
    </ion-card-content>
  </ion-card>

</ion-content>
