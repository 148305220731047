import { Component, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPage, ISearchPage } from '../../models/search-page.interface';

@Component({
  selector: 'smart-search-bar',
  template: `
    <ion-searchbar (ionChange)="search($event)" debounce="300" placeholder="Entrer un thème..."></ion-searchbar>
    <ion-list *ngFor="let page of pages">
      <ion-list-header color="gris1">
        {{page.slug | titlecase}}
      </ion-list-header>
      <ion-item button *ngFor="let anchor of page.search_results_anchors | keyvalue" (click)="goTo.emit({anchor: anchor.key, slug: page.slug, color: page.color_primary})">
        {{anchor.value}}
      </ion-item>
    </ion-list>
  `,
  styleUrls: ['smart-search.scss']
})
export class SmartSearchComponent {

  @Output()
  public readonly goTo: EventEmitter<IPage> = new EventEmitter<IPage>();

  pages: ISearchPage[];

  private mainUrl = 'https://appli.smartbudget-udaf03.fr/wp-json/wp/v2/pages?orderby=menu_order&order=asc';

  constructor(private http: HttpClient) {
  }

  search($event: CustomEvent) {
    if ($event.detail.value !== '') {
      this.http.get(this.mainUrl + '&search=' + $event.detail.value)
        .subscribe((results: ISearchPage[]) => {
          this.pages = results;
        });
    } else {
      this.pages = [];
    }
  }


}
