<ion-header>
  <ion-toolbar class="ion-color" [style.background-color]="page.color">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
      <ion-menu-toggle>
        <ion-button>
          <ion-icon name="menu"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
    <ion-title class="ion-text-capitalize">{{page.slug}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <iframe width="100%" height="100%" [src]="url | safe: 'resourceUrl'"></iframe>
</ion-content>
