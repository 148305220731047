import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';
import { EventsService } from '../events.service';
import { IonNav } from '@ionic/angular';
import { IPage } from '../models/search-page.interface';

@Injectable()
export class UserData {
  _favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
  private nav: IonNav;
  private _page: IPage;

  constructor(
    public events: EventsService,
    public storage: Storage
  ) {
  }

  hasFavorite(sessionName: string): boolean {
    return (this._favorites.indexOf(sessionName) > -1);
  };

  addFavorite(sessionName: string): void {
    this._favorites.push(sessionName);
  };

  removeFavorite(sessionName: string): void {
    let index = this._favorites.indexOf(sessionName);
    if (index > -1) {
      this._favorites.splice(index, 1);
    }
  };

  login(username: string, apitoken: string): void {
    this.storage.set(this.HAS_LOGGED_IN, true);
    this.setUsername(username);
    this.setUserapitoken(apitoken);
    this.events.publishSomeData('user:login');
  };

  getNav() {
    return this.nav;
  };

  setNav(nav: IonNav) {
    this.nav = nav;
  };

  signup(username: string, apitoken: string): void {
    this.storage.set(this.HAS_LOGGED_IN, true);
    this.setUsername(username);
    this.setUserapitoken(apitoken);
    this.events.publishSomeData('user:signup');
  };

  logout(): void {
    this.storage.remove(this.HAS_LOGGED_IN);
    this.storage.remove('username');
    this.storage.remove('apitoken');
    this.events.publishSomeData('user:logout');
  };

  setUsername(username: string): void {
    this.storage.set('username', username);
  };

  setUserapitoken(apitoken: string): void {
    this.storage.set('apitoken', apitoken);
  };

  getUsername(): Promise<string> {
    return this.storage.get('username').then((value) => {
      return value;
    });
  };

  getUserapitoken(): Promise<string> {
    return this.storage.get('apitoken').then((value) => {
      return value;
    });
  };

  hasLoggedIn(): Promise<boolean> {
    return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
      return value === true;
    });
  };

  checkHasSeenTutorial(): Promise<string> {
    return this.storage.get(this.HAS_SEEN_TUTORIAL).then((value) => {
      return value;
    });
  };

  get page() {
    return this._page;
  }

  private set page(page: IPage) {
    this._page = page;
  }

  public updatePage(page: IPage): void {
    this.page = page;
  }
}
