<ion-header>
  <ion-toolbar color="ui">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
      <ion-menu-toggle>
        <ion-button>
          <ion-icon name="menu"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
    <ion-title>Mon budget</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="outer-content">
  <div class="ion-padding-top ion-text-center">
    <ion-card>
      <ion-card-header>
        <ion-row class="ion-no-padding" responsive-sm>
          <ion-col class="ion-col-3 ion-text-left">
            <ion-icon *ngIf="solde_fin_de_mois > 0" size="large" color="secondary" name="happy-outline"></ion-icon>
            <ion-icon *ngIf="solde_fin_de_mois <= 0" size="large" color="danger" name="sad-outline"></ion-icon>
          </ion-col>
          <ion-col size="9">
            <p class="ion-text-left ion-no-margin old-solde"><ion-text color="dark">Ancien solde : {{solde_debut_de_mois}} €</ion-text></p>
            <p class="ion-text-left ion-no-margin new-solde"><ion-text color="dark">Nouveau solde : {{solde_fin_de_mois}} €</ion-text></p>
          </ion-col>
        </ion-row>
      </ion-card-header>

      <ion-list class="ion-no-padding">
        <ion-item *ngFor="let item of mvts" ngClass="poste-{{item.id_poste}} depense">

          <ion-label>{{item.libelle}} <span class="item-label">- {{item.nom}}</span></ion-label>

          <div slot="end">{{item.montant}} €<span *ngIf="item.code_recurent == 'OUI'" class="item-label">/mois</span>
          </div>

          <ion-button class="ion-no-margin" slot="end" fill="transparent" (click)="modifMouvement(item.id,item.montant)">
            <ion-icon slot="icon-only" name="pencil" color="gris3"></ion-icon>
          </ion-button>

          <ion-button  class="ion-no-margin" slot="end" fill="transparent" (click)="suppMouvement(item.id)">
            <ion-icon slot="icon-only" name="trash" color="gris3"></ion-icon>
          </ion-button>

        </ion-item>

      </ion-list>
    </ion-card>

  </div>

  <ion-grid>
    <ion-row>
      <ion-col size="6">
        <ion-button color="danger" (click)="createDepense()" expand="block">Saisir une dépense</ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button color="secondary" (click)="createRevenu()" expand="block">Saisir un revenu</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <p class="ion-text-center username">budget de {{username}}</p>

</ion-content>
