import { Component } from '@angular/core';
import { UserData } from '../../providers/user-data';

@Component({
  selector: 'page-questions',
  templateUrl: 'questions.html',
  styleUrls: ['questions.scss']
})
export class QuestionsPage {

  constructor(public userData: UserData) { }

}
