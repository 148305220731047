import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { SplashScreen } from '@ionic-native/splash-screen';
import { Storage } from '@ionic/storage';
//pages
import { AccueilPage } from './pages/accueil/accueil';
import { QuestionsPage } from './pages/questions/questions';
import { MentionsPage } from './pages/mentions/mentions';
import { AccountPage } from './pages/account/account';
import { HistoPage } from './pages/histo/histo';
import { LoginPage } from './pages/login/login';
import { SignupPage } from './pages/signup/signup';
import { LosepasswordPage } from './pages/losepassword/losepassword';

import { UserData } from './providers/user-data';
import { IonNav, MenuController, Platform } from '@ionic/angular';
import { EventsService } from './events.service';
import { map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ISearchPage } from './models/search-page.interface';
import { ExternalPage } from './pages/external/external';

export interface PageInterface {
  title: string;
  name: string;
  color?: string;
  component: any;
  icon: string;
  logsOut?: boolean;
  index?: number;
  tabName?: string;
  tabComponent?: any;
  active?: boolean;
}

@Component({
  selector: 'ion-app',
  templateUrl: 'app.template.html',
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('nav', {static: false}) nav: IonNav;

  appPages: PageInterface[] = [
    {title: 'Accueil', name: 'AccueilPage', component: AccueilPage, icon: 'chevron-forward-circle-outline', color: 'dark'},
  ];

  loggedInPages: PageInterface[] = [
    {title: 'Mon budget', name: 'AccountPage', component: AccountPage, icon: 'logo-euro'},
    {title: 'Historique', name: 'HistoPage', component: HistoPage, icon: 'podium'},
    {title: 'Déconnexion', name: 'AccueilPage', component: AccueilPage, icon: 'log-out-outline', logsOut: true}
  ];

  loggedOutPages: PageInterface[] = [
    {title: 'Connexion', name: 'LoginPage', component: LoginPage, icon: 'log-in'},
    {title: 'Créer un compte', name: 'SignupPage', component: SignupPage, icon: 'person-add'},
    {title: 'Mot de passe perdu', name: 'LosepasswordPage', component: LosepasswordPage, icon: 'help'}
  ];

  infoPages: PageInterface[] = [
    {title: 'Questions', name: 'QuestionsPage', component: QuestionsPage, icon: 'help-outline'},
    {title: 'Mentions', name: 'MentionsPage', component: MentionsPage, icon: 'information-circle'}
  ];

  rootPage: any;
  active: string;

  constructor(
    private events: EventsService,
    public userData: UserData,
    public menu: MenuController,
    public platform: Platform,
    public http: HttpClient,
    // public splashScreen: SplashScreenOriginal,
    public storage: Storage
  ) {

    // Check if the user has already seen the tutorial
    this.rootPage = AccueilPage;
    this.platformReady();

    // decide which menu items should be hidden by current login status stored in local storage
    this.userData.hasLoggedIn().then((hasLoggedIn) => {
      this.enableMenu(hasLoggedIn === true);
    });

    this.listenToLoginEvents();
  }

  openPage(page: PageInterface) {
    let params = {};
    const slug = page.title.normalize('NFKD')
      .toLowerCase()
      .replace(/[^\w\s-]/g, '')
      .trim()
      .replace(/[-\s]+/g, '-');

    this.userData.updatePage({anchor: '', slug, color: page.color});

    // the nav component was found using @ViewChild(Nav)
    if (page.index) {
      params = {tabIndex: page.index};
    }

    // If we are already on tabs just change the selected tab
    // don't setRoot again, this maintains the history stack of the
    // tabs even if changing them from the menu
    if (page.index != undefined) {
      this.nav.getActive()[0].select(page.index);
    } else {
      // Set the root of the nav with params if it's a tab index
      // bongo this.nav.setRoot(page.name, params).catch((err: any) => {
      this.nav.setRoot(page.component, params).catch((err: any) => {
        console.log(`Didn't set nav root: ${err}`);
      });
    }

    if (page.logsOut === true) {
      // Give the menu time to close before changing to logged out
      //this.username = null;
      //this.apitoken = null;
      this.userData.logout();
    }

    this.menu.close();
  }

  listenToLoginEvents() {
    this.events.getObservable().subscribe((data: string) => {
      console.log(data);
      switch (data) {
        case 'user:login':
          this.enableMenu(true);
          break;
        case 'user:signup':
          this.enableMenu(true);
          break;
        case 'user:logout':
          this.enableMenu(false);
          break;
      }
    }, () => {
      this.enableMenu(false);
    });
  }

  enableMenu(loggedIn: boolean) {
    this.menu.enable(loggedIn, 'loggedInMenu');
    this.menu.enable(!loggedIn, 'loggedOutMenu');
  }

  platformReady() {
    // Call any initial plugins when ready
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });
  }

  ngAfterViewInit(): void {
    this.userData.setNav(this.nav);

    this.nav.ionNavDidChange
      .pipe(
        mergeMap((data: any) => data.target.getActive()),
        map((data: any) => data.component.name)
      )
      .subscribe((data) => this.active = data);
  }

  ngOnInit(): void {
    // Add pages in appPages
    this.http.get('https://appli.smartbudget-udaf03.fr/wp-json/wp/v2/pages?orderby=menu_order&order=asc')
      .subscribe((pages: ISearchPage[]) => {
        pages.forEach((page: ISearchPage) => {
          this.appPages.push({title: page.title.rendered, name: 'ExternalPage', component: ExternalPage, icon: page.picto, color: page.color_primary})
        })
      })
  }
}
